import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/overground/4.jpeg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/tank.jpeg'
import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/inter.jpeg'
import pic06 from '../assets/images/bespoke/1.jpeg'
import pic07 from '../assets/images/fuel/1.jpg'
import pic08 from '../assets/images/giphy.gif'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Marcon Tanks"
                    meta={[
                        { name: 'description', content: 'Marcon Tanks' },
                        { name: 'keywords', content: 'Marcon Tanks, tanks, overground tanks, Underground tanks, Interceptors' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">

                <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>About Marcon Tanks Ltd.</h2>
                            </header>
                            <p>Welcome to Marcon Tanks<br />
                            Marcon Tanks provide a full range of domestic and commercial oil storage tanks ranging from 50ltrs to 200,000ltr tanks. We also manufacture bespoke tanks of any size to suit your needs.</p>
                            <ul className="actions">
                            </ul>
                        </div>
                    </section>
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${pic01})`}}>
                            <header className="major">
                                <h3>Overground Tanks</h3>
                            </header>
                            <Link to="/overground" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic02})`}}>
                            <header className="major">
                                <h3>Underground Tanks</h3>
                            </header>
                            <Link to="/underground" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic04})`}}>
                            <header className="major">
                                <h3>Bunded Tanks</h3>
                            </header>
                            <Link to="/bunded" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic05})`}}>
                            <header className="major">
                                <h3>Interceptors</h3>
                            </header>
                            <Link to="/interceptors" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic03})`}}>
                            <header className="major">
                                <h3>Slurry Tank Refurbishment</h3>
                            </header>
                            <Link to="/slurry" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic07})`}}>
                            <header className="major">
                                <h3>Fuel Equipment</h3>
                            </header>
                            <Link to="/fuelequiptment" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic06})`}}>
                            <header className="major">
                                <h3>Bespoke Tanks</h3>
                            </header>
                            <Link to="/bespoke" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic08})`}}>
                            <header className="major">
                                <h3>Contact</h3>
                            </header>
                            <Link to="/contact" className="link primary"></Link>
                        </article>
                    </section>
                   
                </div>

            </Layout>
        )
    }
}

export default HomeIndex
