import React from 'react'
import marcon from '../assets/images/looogo.png'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <img src={marcon} alt="" className="center"/>
            </header>
        </div>
    </section>
)

export default Banner
